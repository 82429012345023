<template lang="pug">
  .tab-pane(v-show="isActive")
    slot(:isActive="isActive")
</template>

<script>
export default {
  name: 'TabsPaneComponent',

  props: {
    name: {
      type: String,
      required: true
    },

    iconName: {
      type: String,
      default: ''
    },

    badge: {
      type: [String, Number],
      default: ''
    }
  },

  data: () => ({
    isActive: true
  }),

  watch: {
    badge() {
      this.$parent.getPanes(true)
    }
  }
}
</script>

<style lang="sass" scoped>
.tab-pane
  margin-left: $tabs-horizontal-offset
  margin-right: $tabs-horizontal-offset
</style>
