<template lang="pug" src="./Template.pug"></template>

<script>
import { mapState } from 'vuex'
import TabsPaneComponent from '~~/components/Common/Tabs/TabsPane'
import TabsComponent from '~~/components/Common/Tabs/Tabs'

const width = 925
const ratio = 0.56349

export default {
  name: 'PackagingComponent',

  components: {
    GalleryLightbox: () => import('./PackagingLightbox'),
    VideoModal: () => import('~~/components/Common/VideoModal'),
    TabsPaneComponent,
    TabsComponent
  },

  data: () => ({
    swiper: null,
    modalName: 'packaging-video',
    selectedIndex: 0,
    showVideoModal: false,
    showGalleryLightbox: false,
    modalOptions: {
      autoplay: true,
      controls: true,
      muted: false,
      width,
      height: width * ratio,
      source:
        'https://video.thenaturalsapphirecompany.com/4a891d82-67c2-4509-842a-e08490f5c820/hls/packaging.m3u8'
    }
  }),

  computed: {
    ...mapState({
      priceThreshold: (state) =>
        state.app.documentPriceConfig.priceThreshold.default
    }),

    sliderParams() {
      const params = {
        loop: true,
        autoplay: true,
        autoHeight: true,
        allowTouchMove: this.$device.isMobileOrTablet,
        on: {
          // workaround for duplicated sliders (duplicated slides don't copying properly)
          slideChangeTransitionStart: (swiper) => {
            const $wrapperEl = swiper.$wrapperEl
            const params = swiper.params
            $wrapperEl
              .children(
                '.' + params.slideClass + '.' + params.slideDuplicateClass
              )
              .each(function () {
                const idx = this.getAttribute('data-swiper-slide-index')
                this.innerHTML = $wrapperEl
                  .children(
                    '.' +
                      params.slideClass +
                      '[data-swiper-slide-index="' +
                      idx +
                      '"]:not(.' +
                      params.slideDuplicateClass +
                      ')'
                  )
                  .html()
              })
          },

          // workaround for duplicated sliders (duplicated slides don't copying properly)
          slideChangeTransitionEnd: (swiper) => {
            swiper.slideToLoop(swiper.realIndex, 0, false)
          }
        },
        pagination: {
          el: '.packaging-swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      }

      if (this.$device.isDesktop) {
        params.navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }

      return params
    },

    images() {
      return [
        {
          guid: '4741aad7-be21-44c3-aeb3-2a2f1bedda47',
          image: {
            micro: 'packaging/package1@2x.jpg',
            large: 'packaging/large/package1@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.pricePrint', {
            price: this.priceThreshold
          })
        },
        {
          guid: 'a66d5da0-81fe-48b4-8a4e-0a5b9553d0f6',
          image: {
            micro: 'packaging/package2@2x.jpg',
            large: 'packaging/large/package2@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: '7bd22820-3e8d-4691-b6fc-5ad640db3bfd',
          image: {
            micro: 'packaging/package3@2x.jpg',
            large: 'packaging/large/package3@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: 'd2b84e2f-6e50-4d61-9c7e-01e6a6e699c9',
          image: {
            micro: 'packaging/package4@2x.jpg',
            large: 'packaging/large/package4@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.largeZincBox')
        },
        {
          guid: 'd349910b-1ab7-488a-b771-497a87a78b1f',
          image: {
            micro: 'packaging/package5@2x.jpg',
            large: 'packaging/large/package5@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: 'df7f58b1-d174-4561-9cd8-56a460634e40',
          image: {
            micro: 'packaging/package6@2x.jpg',
            large: 'packaging/large/package6@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        // {
        //   guid: 'eb7d3f2b-b8a9-4b6d-948f-420360571789',
        //   image: {
        //     micro: 'packaging/package7@2x.jpg',
        //     large: 'packaging/large/package7@2x.jpg'
        //   },
        //   type: 'Image',
        //   alt: this.$t('imagesAlt.packaging'),
        //   text: this.$t('homePage.packaging.smallZincBox')
        // },
        {
          guid: 'fea9f9c3-7dfc-4d25-be2c-15e254734646',
          image: {
            micro: 'packaging/package8@2x.jpg',
            large: 'packaging/large/package8@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.smallZincBox')
        },
        {
          guid: '982914e1-0fb6-4c88-9e9d-38bb117ff692',
          image: {
            micro: 'packaging/package9@2x.jpg',
            large: 'packaging/large/package9@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: '9f0f0c99-1f7d-45bf-938f-c73407ca3022',
          image: {
            micro: 'packaging/package10@2x.jpg',
            large: 'packaging/large/package10@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.largeZincBox')
        },
        {
          guid: '9f0f0c39-1f7d-45bf-938f-c73407ca3039',
          image: {
            micro: 'packaging/package11@2x.jpg',
            large: 'packaging/large/package11@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.blackBoxAndTextCompany')
        },
        {
          guid: 'df612ce1-6521-4805-9d42-c4e5eb0e70c8',
          image: {
            micro: 'packaging/package12@2x.jpg',
            large: 'packaging/large/package12@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.necklaceLowCost')
        },
        {
          guid: '6e9911e9-4725-4298-87af-98644cc72b89',
          image: {
            micro: 'packaging/package13@2x.jpg',
            large: 'packaging/large/package13@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.earring')
        },
        {
          guid: 'bd2451bf-aa97-4af5-96c8-343c0d079041',
          image: {
            micro: 'packaging/package14@2x.jpg',
            large: 'packaging/large/package14@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.bracelet')
        }
      ]
    }
  },

  methods: {
    async initSwiper(isVisible) {
      if (isVisible) {
        this.swiper = await this.$swiper.insert(
          this.$refs['packaging-slider'],
          this.sliderParams
        )
      }
    },

    mountLightBox(index) {
      this.showGalleryLightbox = true // Avoid an excessive DOM size
      this.swiper.autoplay.stop()
      this.selectedIndex = index
      this.currentSlideIndex = index
      setTimeout(() => {
        this.$root.$emit('packaging-lightbox', {
          thumbnails: this.images,
          imageIndex: this.selectedIndex,
          imageId: this.images[this.selectedIndex].guid
        })
      }, 10)
    },

    /**
     * Avoid an excessive DOM size
     */
    onVideoPlayHandler() {
      this.showVideoModal = true
      setTimeout(() => {
        this.$root.$emit('packaging-video')
      }, 10)
    }
  }
}
</script>

<style lang="sass" scoped>
@include background-image('packaging', 'packaging/bg', 'jpg')
.packaging
  margin-top: 100px
  background-color: $primary-color
  height: 680px
  background-size: auto 100%
  background-position-x: 50%
  background-position-y: center
  background-repeat: no-repeat
  overflow: hidden
  +touch
    margin-top: 45px
    background-position-x: 65%
    height: 670px
  +mobile
    height: unset
    background-repeat: no-repeat
    background-size: 310%
    background-position-y: bottom
    background-position-x: 78%
    background-color: $primary-color
  .container
    height: 100%
    align-items: center
    +touch
      padding: 45px 0
    +mobile
      flex-direction: column
      align-items: flex-start
      margin-right: 30px
    +iphone-5se
      margin-right: 20px
  .swiper-container
    width: 50%
    height: 100%
    max-height: 580px
    +desktop-only
      max-height: 560px
    +mobile
      width: 100%
      max-height: unset
    .swiper-slide
      > *
        height: 580px
        +desktop-only
          height: 560px
        +mobile
          height: 570px
    .swiper-button-next,
    .swiper-button-prev
      outline: none
      z-index: 1
      color: $dark-primary-color
      transform: translateY(-50%)
    .packaging-swiper-pagination
      ::v-deep
        .swiper-pagination-bullet
          background: $primary-color
          opacity: .5
          outline: none
          &-active
            background: $primary-color
            opacity: 1
  &-content
    cursor: pointer
    padding: 40px 70px
    background-color: #fff
    height: 100%
    width: 100%
    justify-content: center
    align-items: center
    +mobile
      min-height: 490px
      padding: 40px 25px
    &__text
      +touch
        margin-top: 30px
  &-info
    padding: 40px 70px
    background-color: #fff
    flex-direction: column
    height: 100%
    +touch
      padding: 25px
    ::v-deep
      .tab-name
        text-transform: uppercase
      .tab-pane
        white-space: normal
        padding: 15px 0
  &-info-tabs
    margin-top: 40px
  &-info-sub-title
    text-transform: uppercase
  &-info-title
    margin-top: 15px
    font-family: $lora
    font-size: 36px
    font-style: italic
    +touch
      font-size: 30px
  &-info-text
    font-size: 16px
  &-video
    justify-content: center
    align-items: center
    width: 50%
    +mobile
      min-height: 300px
      width: 100%
      padding-top: 15px
      margin-left: 15px
    +iphone-5se
      margin-left: 10px
    .picture-wrapper
      cursor: pointer
</style>
