<template
  lang="pug"
  src="~/components/HomePage/Packaging/Template.pug"
></template>

<script>
import { mapState } from 'vuex'
import Packaging from '~~/components/HomePage/Packaging/Packaging'

const width = 925
const ratio = 0.56349

export default {
  name: 'PackagingComponent',

  extends: Packaging,

  data: () => ({
    thumbnailsSwiper: null,
    modalOptions: {
      autoplay: true,
      controls: true,
      muted: false,
      width,
      height: width * ratio,
      source:
        'https://video.thenaturalsapphirecompany.com/ee1f0d5e-bc5d-4691-949a-282e16057161/hls/packaging.m3u8'
    }
  }),

  computed: {
    ...mapState({
      priceThreshold: (state) =>
        state.app.documentPriceConfig.priceThreshold.default
    }),

    images() {
      return [
        // {
        //   guid: '35fa57cc-2793-47d2-a888-68de6e0ad0aa',
        //   image: {
        //     micro: 'packaging/package0@2x.jpg'
        //     // large: 'packaging/large/package0@2x.jpg'
        //   },
        //   url: '',
        //   type: 'Video',
        //   alt: this.$t('imagesAlt.packaging') // изменить описание
        // },
        {
          guid: '4741aad7-be21-44c3-aeb3-2a2f1bedda47',
          image: {
            micro: 'packaging/package1@2x.jpg',
            large: 'packaging/large/package1@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.pricePrint', {
            price: this.priceThreshold
          })
        },
        {
          guid: 'a66d5da0-81fe-48b4-8a4e-0a5b9553d0f6',
          image: {
            micro: 'packaging/package2@2x.jpg',
            large: 'packaging/large/package2@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: '89c8f69e-669a-455f-b821-2e4813980b9e',
          image: {
            micro: 'packaging/package3@2x.jpg',
            large: 'packaging/large/package3@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.largeZincBox')
        },
        {
          guid: 'd2b84e2f-6e50-4d61-9c7e-01e6a6e699c9',
          image: {
            micro: 'packaging/package4@2x.jpg',
            large: 'packaging/large/package4@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.slide4.text')
        },
        {
          guid: 'd349910b-1ab7-488a-b771-497a87a78b1f',
          image: {
            micro: 'packaging/package5@2x.jpg',
            large: 'packaging/large/package5@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: 'df7f58b1-d174-4561-9cd8-56a460634e40',
          image: {
            micro: 'packaging/package6@2x.jpg',
            large: 'packaging/large/package6@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.necklaceLowCost')
        },
        {
          guid: 'eb7d3f2b-b8a9-4b6d-948f-420360571789',
          image: {
            micro: 'packaging/package7@2x.jpg',
            large: 'packaging/large/package7@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: 'fea9f9c3-7dfc-4d25-be2c-15e254734646',
          image: {
            micro: 'packaging/package8@2x.jpg',
            large: 'packaging/large/package8@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.blackBoxAndTextCompany')
        },
        {
          guid: '982914e1-0fb6-4c88-9e9d-38bb117ff692',
          image: {
            micro: 'packaging/package9@2x.jpg',
            large: 'packaging/large/package9@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging')
        },
        {
          guid: '113b86c8-96d9-43be-97b2-a77b74f4530b',
          image: {
            micro: 'packaging/package10@2x.jpg',
            large: 'packaging/large/package10@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.smallZincBox')
        },
        {
          guid: 'baabbd36-240a-4cc0-b8e8-135f1cf29633',
          image: {
            micro: 'packaging/package11@2x.jpg',
            large: 'packaging/large/package11@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.largeZincBox')
        },
        {
          guid: 'ce83c828-78d3-4c54-a759-d34d7eba0d0a',
          image: {
            micro: 'packaging/package12@2x.jpg',
            large: 'packaging/large/package12@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.bracelet')
        },
        {
          guid: 'f7a5f0c0-6f0c-4f2c-8c3e-9e2c0b6a5f0c',
          image: {
            micro: 'packaging/package13@2x.jpg',
            large: 'packaging/large/package13@2x.jpg'
          },
          type: 'Image',
          alt: this.$t('imagesAlt.packaging'),
          text: this.$t('homePage.packaging.earring')
        }
      ]
    },

    thumbnailsParams() {
      const params = {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 18,
        slideToClickedSlide: true,
        freeMode: true,
        watchSlidesProgress: true,
        allowTouchMove: this.$device.isMobileOrTablet,
        on: {
          init: function () {
            if (window.lazyUpdate) window.lazyUpdate()
          }
        }
      }
      return params
    },

    sliderParams() {
      return {
        loop: true,
        autoHeight: true,
        allowTouchMove: this.$device.isMobileOrTablet,
        on: {
          // workaround for duplicated sliders (duplicated slides don't copying properly)
          slideChangeTransitionStart: (swiper) => {
            const $wrapperEl = swiper.$wrapperEl
            const params = swiper.params
            $wrapperEl
              .children(
                '.' + params.slideClass + '.' + params.slideDuplicateClass
              )
              .each(function () {
                const idx = this.getAttribute('data-swiper-slide-index')
                this.innerHTML = $wrapperEl
                  .children(
                    '.' +
                      params.slideClass +
                      '[data-swiper-slide-index="' +
                      idx +
                      '"]:not(.' +
                      params.slideDuplicateClass +
                      ')'
                  )
                  .html()
              })
          },

          // workaround for duplicated sliders (duplicated slides don't copying properly)
          slideChangeTransitionEnd: (swiper) => {
            swiper.slideToLoop(swiper.realIndex, 0, false)
          },
          init: function () {
            if (window.lazyUpdate) window.lazyUpdate()
          },

          slideChange: (swiper) => {
            if (this.thumbnailsSwiper) {
              if (swiper.realIndex === 0) {
                this.removeActiveClass(this.thumbnailsSwiper.slides)
              } else {
                this.thumbnailsSwiper.slideToLoop(swiper.realIndex - 1)
              }
            }
          }
        },
        thumbs: {
          swiper: this.thumbnailsSwiper
        }
      }
    }
  },

  methods: {
    async initSwiper(isVisible) {
      if (isVisible) {
        this.swiper = await this.$swiper.insert(
          this.$refs['packaging-slider'],
          this.sliderParams
        )
        if (this.swiper) {
          await this.initSwiperThumbnails(true)
          if (this.swiper.realIndex === 0) {
            this.removeActiveClass(this.thumbnailsSwiper.slides)
          }
        }
      }
    },

    async initSwiperThumbnails(isVisible) {
      if (isVisible) {
        this.thumbnailsSwiper = await this.$swiper.insert(
          this.$refs.thumbnails,
          this.thumbnailsParams
        )
      }
    },

    removeActiveClass(slidesList) {
      const slidesArray = Array.from(slidesList)
      slidesArray.forEach((slide) => {
        slide.classList.remove('swiper-slide-active')
      })
    },

    onThumbnailContainerClick(event) {
      const slideElement = event.target.closest('.swiper-slide')
      if (slideElement) {
        const slideIndex = Number(
          slideElement.getAttribute('data-swiper-slide-index')
        )
        this.swiper.slideToLoop(slideIndex + 1)
        this.thumbnailsSwiper.slideToLoop(slideIndex)
      }
    },

    onNextClick() {
      if (this.swiper && this.thumbnailsSwiper) {
        const realIndex = this.swiper.realIndex
        this.swiper.slideToLoop(realIndex + 1)

        this.thumbnailsSwiper.slideToLoop(realIndex)
      }
    },

    onPrevClick() {
      if (this.swiper && this.thumbnailsSwiper) {
        const realIndex = this.swiper.realIndex
        if (realIndex === 1) {
          this.swiper.slideToLoop(realIndex - 1)
          this.thumbnailsSwiper.slideToLoop(0)
          this.removeActiveClass(this.thumbnailsSwiper.slides)
        } else if (realIndex === 0) {
          this.swiper.slideToLoop(this.images.length)
          this.thumbnailsSwiper.slideToLoop(this.images.length - 1)
        } else {
          this.swiper.slideToLoop(realIndex - 1)
          this.thumbnailsSwiper.slideToLoop(realIndex - 2)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@include background-image('packaging', 'packaging/bg', 'jpg')
.packaging
  background-color: #311C41
  height: 1080px
  background-size: auto 100%
  background-position-x: 50%
  background-position-y: center
  background-repeat: no-repeat
  overflow: hidden
  +until-widescreen
    height: 900px
  +touch
    margin-top: 45px
    background-position-x: 65%
    height: 719px
  +mobile
    height: unset
    background-repeat: no-repeat
    background-size: 310%
    background-position-y: bottom
    background-position-x: 78%
    background-color: #311C41
  &-slider
    width: 50%
    height: 533px
    +until-widescreen
      max-height: unset
      height: auto
    +mobile
      width: 100%
  .container
    height: 100%
    align-items: center
    +touch
      padding: 45px 20px
    +mobile
      flex-direction: column
      align-items: flex-start
  .swiper-container__main
    width: 100%
    max-height: 292px
    +until-widescreen
      max-height: none
    +mobile
      min-height: 289px
      max-height: none
    .swiper-slide.swiper-main
      .packaging-content
        display: grid!important
        grid-template-columns: 1fr
        grid-template-rows: 1fr
        height: 289px
        +until-widescreen
          height: 360px
        +touch
          height: 398px
        &.is-text
          grid-template-rows: minmax(47%, 88%) auto

      ::v-deep
        .picture-wrapper
          max-height: 289px
          +until-widescreen
            max-height: 360px
          +touch
            max-height: 398px
        .picture-wrapper,
        picture,
        img
          height: 100%
          object-fit: contain
  .packaging-slider
    & > :first-child
      max-width: 650px
      margin: 0 auto
      background: #fff
      padding: 60px 50px
      height: 100%
      display: flex
      flex-direction: column
      justify-content: space-between
      gap: 40px
      +touch
        padding: 40px 20px
        max-width: 354px
        margin: 0
      +mobile
        max-width: 100%
        width: 100%
      & > :nth-child(2)
        position: relative
        width: 100%
        padding-left: 20px
        padding-right: 20px
        +touch
          padding-left: 19px
          padding-right: 19px
        +mobile
          padding-left: 9px
          padding-right: 9px
  &-content
    cursor: pointer
    background-color: #fff
    height: 100%
    width: 100%
    justify-content: center
    align-items: center
    &__text
      +touch
        margin-top: 30px
  &-info
    text-align: center
    background-color: #fff
    flex-direction: column
    height: 100%
  &-info-sub-title
    text-transform: uppercase
    font-size: 12px
    line-height: 24px
  &-info-title
    margin-top: 20px
    font-family: $lora
    font-size: 36px
    line-height: 42px
    font-style: italic
    margin-bottom: 20px
    +touch
      font-size: 32px
      padding: 0 5px
  &-info-text
    font-size: 16px
    line-height: 24px
  &-video
    justify-content: center
    align-items: center
    width: 50%
    +mobile
      min-height: 300px
      width: 100%
      padding-top: 15px
      margin-left: 15px
    +iphone-5se
      margin-left: 10px
    .picture-wrapper
      cursor: pointer

.swiper-container__thumbnails
  width: 100%
  height: 100%
  .swiper-slide
    width: 80px!important
    height: 80px!important
    border: 1px solid $border-color
    overflow: hidden
    ::v-deep
      img
        height: 80px
        width: 80px
        object-fit: cover
        object-position: center
    &.swiper-slide-active
      border: 1px solid $primary-color
    .packaging-content
      padding: 0!important

.swiper-button-next
  right: 0px
  +mobile
    right: -9px
  &::after
    content: none
.swiper-button-prev
  left: 0px
  +mobile
    left: -9px
  &::after
    content: none
  svg
    transform: rotate(180deg)
.swiper-button-next,
.swiper-button-prev
  width: 40px
  height: 40px
  display: flex
  align-items: center
  justify-content: center
  background: #fff
  box-shadow: 0 1px 8px #00000029
  border-radius: 50%
  top: 53px
  transform: translateY(-50%)
  svg
    width: 24px
    height: 24px
.packaging
  &.initial-load
    .swiper-container__thumbnails
      .swiper-slide-active
        border: 1px solid $border-color !important
</style>
