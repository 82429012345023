<template lang="pug">
.tabs.tabs-wrapper
  tabs-nav(:panes="panes", @change="onTabChangeHandler")
  slot
</template>

<script>
import get from 'lodash/get'
import TabsNav from './TabsNav'
import { guid } from '~~/utils/utils'
export default {
  name: 'TabsComponent',

  components: {
    TabsNav
  },

  props: {
    active: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    panes: []
  }),

  watch: {
    active(tabName) {
      if (tabName) {
        this.panes.forEach((pane) => {
          pane.isActive = tabName === pane.name
          pane.pane.isActive = pane.isActive
        })
      }
    }
  },

  mounted() {
    this.getPanes()
  },

  methods: {
    getPanes(force = false) {
      let activeId = this.panes.findIndex((pane) => pane.isActive)
      if (activeId === -1) activeId = 0
      if (this.panes.length > 0 && !force) return
      const panes = []
      const vNodes = this.$slots.default.filter(
        (vNode) =>
          vNode.tag &&
          vNode.componentOptions &&
          vNode.componentOptions.tag === 'tabs-pane-component'
      )
      const tabPanes = vNodes.map(({ componentInstance }) => componentInstance)
      tabPanes.forEach((pane, index) => {
        const isActive = this.active
          ? pane.name === this.active
          : index === activeId
        const tabObj = {
          id: guid(),
          isActive
        }

        pane.isActive = isActive

        panes.push({
          ...tabObj,
          ...get(pane, '$options.propsData', {}),
          pane
        })
      })
      this.panes = panes
    },

    onTabChangeHandler(tableName) {
      this.panes.forEach((pane) => {
        pane.isActive = pane.name === tableName
        pane.pane.isActive = pane.isActive
        if (pane.name === tableName) this.$emit('change', tableName)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs-wrapper
  margin-left: -$tabs-horizontal-offset
  margin-right: -$tabs-horizontal-offset
  flex-direction: column
</style>
