<template lang="pug">
  .tabs-nav-wrapper
    ul.tabs-nav
      li.is-flex(v-for="tab in panes", :key="tab.id", @click="$emit('change', tab.name)", :class="{'is-active': tab.isActive}")
        icon.is-hidden-tablet(v-if="tab.iconName", :icon-name="tab.iconName", :icon-color="getIconColor(tab.isActive)")
        .tab-name {{ tab.name }}
          span.badge(v-show="tab.badge") {{ tab.badge }}
</template>

<script>
export default {
  name: 'TabsNavComponent',

  props: {
    panes: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    getIconColor(isActive) {
      return isActive ? this.$site.primaryColor : '#8789ad'
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs-nav
  /*height: 70px*/
  margin: 0 $tabs-horizontal-offset
  border-bottom-color: $grey-text-color
  border-bottom-width: 2px
  +mobile
    border-bottom-width: 2px
    align-items: flex-end

  li
    +mobile
      flex-direction: column
      align-items: center
    .tab-name
      font-weight: bold
      color: $grey-text-color
      border-bottom-color: #878787
      border-bottom-width: 2px
      margin-bottom: unset
      padding: 0.5em 1em 6px
      cursor: pointer
      +mobile
        font-size: 14px
        font-weight: 600
        line-height: 22px
        padding-top: 0
        width: 100%

      .badge
        width: 24px
        height: 24px
        @include border-radius(50%)
        background-color: $grey-text-color
        color: #fff
        line-height: 23px
        text-align: center
        margin-left: 5px

    &.is-active
      .tab-name
        color: $primary-color
        margin-bottom: unset
        box-shadow: 0px 2px 0px $primary-color
        padding-bottom: 6px
        +mobile
          color: $primary-color
        .badge
          background-color: $dark-primary-color
</style>
